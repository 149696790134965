.selectedTab {
  padding: 1rem 1.75rem;
}

.selectedTab .tabHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.selectedTab .tabHeader .tabTitle {
  color: #555a5c;
  font-size: 13px;
}

.selectedTab .tabHeader .tabTitle span {
  color: #19302d;
  font-weight: 600;
}

.students .studentDetailCard .search {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.students .studentDetailCard .search .searchInput {
  position: relative;
}
.students .studentDetailCard .search .searchInput svg {
  position: absolute;
  top: 8.2px;
  left: 12px;
  width: 15px;
}

.students .studentDetailCard .search .searchInput input {
  border: 1px solid #dadada;
  border-radius: 10px;
  padding: 10px 10px 10px 35px;
  background-color: transparent;
  width: 225px;
  outline: none;
  color: #494949;
}

.students .studentDetailCard .search .filter {
  position: relative;
}

.students .studentDetailCard .search .filter svg {
  position: absolute;
  top: 8.2px;
  left: 12px;
  width: 15px;
}

.students .studentDetailCard .search .filter button {
  border: none;
  border-radius: 10px;
  padding: 10px 15px 10px 35px;
  background-color: #494949;
  outline: none;
  color: #ffffff;
  cursor: pointer;
}

.students
  .studentDetail
  .studentDetailCard
  .selectedTab
  .tabBody
  .requestCards {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  margin-top: 1rem;
}
.studentDetail .selectedTab .tabBody .requestCard {
  background-color: #ffffff;
  border: 1px solid #f6f8f7;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px #b8b4b44d;
  width: 30%;
}
.studentDetail .selectedTab .tabBody .requestCardHeader {
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.studentDetail .selectedTab .tabBody .requestCardHeader .icon {
  width: 45px;
  height: 45px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #edf6fe;
}
.studentDetail .selectedTab .tabBody .requestCardHeader .icon svg {
  width: 25px;
}
.studentDetail .selectedTab .tabBody .requestCardHeader .cardTitle {
  font-size: 13px;
  color: #252a31;
}
.studentDetail .selectedTab .tabBody .requestCardHeader .cardTitle .location {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  color: #494949;
  gap: 5px;
  margin-top: 5px;
}
.studentDetail
  .selectedTab
  .tabBody
  .requestCardHeader
  .cardTitle
  .location
  svg {
  width: 15px;
}

.studentDetail .selectedTab .tabBody .requestCard .requestCardBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #f8f8f8;
  color: #494949;
  font-size: 13px;
}
.studentDetail .selectedTab .tabBody .requestCard .requestCardBody button {
  border: 2px solid #dddddd;
  border-radius: 8px;
  padding: 8px 10px;
  font-size: 13px;
  background-color: transparent;
  color: #494949;
  cursor: pointer;
}
.studentDetail .selectedTab .tabBody .requestCard .requestCardBody .responses {
  font-size: 12px;
  color: #494949;
}

.requestDetailSliderBG {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: end;
  background-color: #5d5d5d70;
  overflow: hidden;
}

.requestDetailSliderBG .requestDetailSlider {
  background-color: #f7f7f7;
  width: 30%;
  height: 100%;
  padding: 2rem 1rem;
}

.requestSliderOverlay.overlay-after-open {
  background-color: rgba(0, 0, 0, 0.07) !important;
}

.requestSlider {
  box-shadow: 0px 4px 17px 0px #b8b4b496 !important;
  background-color: #f7f7f7 !important;
}

.requestSlider .slide-pane__content {
  padding: 2rem 1rem;
}

.requestSlider .requestCardHeader .icon {
  width: 45px;
  height: 45px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #edf6fe;
}
.requestSlider .requestCardHeader .icon svg {
  width: 25px;
}
.requestSlider .requestCardHeader .cardTitle {
  font-size: 13px;
  color: #252a31;
}
.requestSlider .requestCardHeader .cardTitle .location {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  color: #494949;
  gap: 5px;
  margin-top: 5px;
}
.requestSlider .requestCardHeader .cardTitle .location svg {
  width: 8px;
}
.requestSlider .responses {
  color: #494949;
  font-size: 14px;
  padding: 1rem 0;
}

.btchesJoined .requestCards .requestDeatailCards {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.btchesJoined .requestCards .requestDeatailCard {
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 2px 7px 0px #b8b4b44d;
  /* padding: 1rem 1rem; */
  width: 43%;
}
.btchesJoined .requestCards .requestDeatailCard .requestDeatailCardMain {
  /* background-color: #ffffff; */
  /* border-radius: 15px; */
  /* box-shadow: 0px 4px 7px 0px #b8b4b44d; */
  padding: 1rem 1rem;
  /* width: 38%; */
}

.btchesJoined .requestCards .requestDeatailCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 0.5rem;
  font-size: 14px;
  color: #252a31;
}

.btchesJoined .requestCards .requestDeatailCardHeader .section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.btchesJoined .requestCards .requestDeatailCardHeader .icon {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #edf6fe;
}
.btchesJoined .requestCards .requestDeatailCardHeader .icon svg {
  width: 18px;
}
.btchesJoined .requestCards .requestDeatailCardHeader .title {
  font-size: 13px;
  color: #252a31;
}
.btchesJoined .requestCards .requestDeatailCardHeader .amount {
  font-size: 13px;
  color: #494949;
  font-weight: 600;
  background-color: #ececec;
  padding: 5px 8px;
  border-radius: 8px;
}

.btchesJoined .requestCards .requestDeatailCardBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0 0.5rem;
  font-size: 14px;
  color: #252a31;
}

.btchesJoined .requestCards .requestDeatailCardBody .section {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
}

.btchesJoined .requestCards .requestDeatailCardBody .section .userImg {
  width: 45px;
  height: 45px;
  border-radius: 100%;
}
.btchesJoined .requestCards .requestDeatailCardBody .section .userImg img {
  border-radius: 100%;
  max-width: 100%;
}

.btchesJoined
  .requestCards
  .requestDeatailCardBody
  .section
  .cardUserData
  .userName {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  color: #252a31;
  font-size: 14px;
}

.btchesJoined
  .requestCards
  .requestDeatailCardBody
  .section
  .cardUserData
  .userName
  svg {
  width: 18px;
}

.btchesJoined
  .requestCards
  .requestDeatailCardBody
  .section
  .cardUserData
  .ratings {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
}

.btchesJoined
  .requestCards
  .requestDeatailCardBody
  .section
  .cardUserData
  .ratings
  svg {
  width: 12px;
}

.btchesJoined .requestCards .requestDeatailCardBody .btn button {
  border: 1px solid #dddddd;
  border-radius: 8px;
  background-color: transparent;
  padding: 5px 7px;
  cursor: pointer;
}

.btchesJoined .requestCards .timings {
  text-align: left;
  font-size: 12px;
  color: #252a31;
}
.btchesJoined .requestCards .timings .days {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.btchesJoined .requestCards .timings .days span {
  background-color: #4cabfd;
  color: #ffffff;
  border-radius: 10px;
  padding: 3px 8px;
}
.btchesJoined .requestCards .requestDeatailCardFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f2f2f2;
  padding: 0.5rem 1rem;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.btchesJoined .requestCards .requestDeatailCardFooter .nextBatch {
  color: #252a31;
  font-size: 13px;
  text-align: right;
}

.btchesJoined .requestCards .requestDeatailCardFooter .nextBatch .title {
  color: #8e9298;
  font-size: 12px;
}

/* .requestSlider .requestCardHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 0.5rem;
} */
.requestSlider .container {
  padding: 1rem 0;
}

.requestSlider .container .user {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.requestSlider .container .user .img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.requestSlider .container .user .img img {
  max-width: 100%;
  border-radius: 100%;
}
.requestSlider .container .user .userData {
  color: #252a31;
  font-size: 14px;
  font-weight: 400;
}
.requestSlider .container .user .userData span {
  font-style: italic;
}

.requestSlider .container .user .ratings {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
}
.requestSlider .container .user .ratings svg {
  width: 12px;
}
.requestSlider .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  border-top: 1px solid #e5e5e5;
}
.requestSlider .row .rowCol {
  color: #252a31;
  font-size: 14px;
}
.requestSlider .row .rowCol:last-child {
  text-align: right;
}
.requestSlider .row .rowCol .rowColTitle {
  color: #8e9298;
}

.requestSlider .row .grpImgs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.requestSlider .row .grpImgs .imgs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
}

.requestSlider .row .grpImgs .imgs img {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  margin-left: -10px;
  border: 2px solid #ffffff;
}

.requestSlider .reviews .reviews {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  margin-top: 1rem;
}
.requestSlider .reviews .reviews .rating {
  font-size: 20px;
  color: #252a31;
}
.requestSlider .reviews .reviews .outOf {
  font-size: 13px;
  color: #8e9298;
}
.requestSlider .reviews .reviews .title {
  font-size: 14px;
  color: #252a31;
  margin-top: 5px;
}

.requestSlider .reviews .reviewStats {
  margin-top: 1rem;
}

.requestSlider .reviews .reviewStats .reviewRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin: 0.5rem 0;
}
.requestSlider .reviews .reviewStats .count {
  width: 5%;
  color: #4d4d4d;
  font-size: 14px;
}

.requestSlider .reviews .reviewStats .reviewBg {
  width: 95%;
  height: 3px;
  border-radius: 5px;
  background-color: #e8e9ec;
}
.requestSlider .reviews .reviewStats .reviewBg .reviewCount {
  height: 3px;
  border-radius: 5px;
  background-color: #f5a041;
}

.requestSlider .reiewCards {
  margin-top: 2rem;
}

.requestSlider .reiewCards .requestCard {
  background-color: #ffffff;
  border: 1px solid #f6f8f7;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px #b8b4b44d;
}

.requestSlider .reiewCards .requestCard .requestCardBody {
  gap: 10px;
  align-items: inherit !important;
  padding: 1rem 1rem 1.5rem !important;
}
.requestSlider .reiewCards .requestCard .requestCardBody .txnRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  border-bottom: 1px solid #d3d4d5;
  color: #252a31;
  font-size: 11px;
}
.requestSlider .requestCard .requestCardBody .txnRow .feedUserData {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  color: #7a7e83;
  font-size: 13px;
}
.requestSlider .requestCard .requestCardBody .txnRow .feedUserData span {
  color: #252a31;
}
.requestSlider .requestCard .requestCardBody .txnRow .feedUserData .userImg {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.requestSlider
  .requestCard
  .requestCardBody
  .txnRow
  .feedUserData
  .userImg
  img {
  max-width: 100%;
  border-radius: 100%;
}

.requestSlider .requestCard .requestCardBody .txnRow .feedUserData .ratings {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
}
.requestSlider
  .requestCard
  .requestCardBody
  .txnRow
  .feedUserData
  .ratings
  svg {
  width: 13px;
}

.requestSlider .requestCard .requestCardBody .reviewText {
  font-size: 12px;
  color: #747474;
  padding-top: 0.5rem;
}
