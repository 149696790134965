.batchesCreated .requestCards .requestDeatailCardHeader .amount span {
  font-weight: 500;
}

.batchesCreated .requestCards .cardStats {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  padding: 1rem 0 0;
}

.batchesCreated .requestCards .cardStat {
  color: #252a31;
  text-align: left;
}
.batchesCreated .requestCards .cardStat .title {
  color: #a7a9ac;
  margin-bottom: 5px;
}

.batchesCreated .requestDeatailCardBody .nextBatch {
  color: #252a31;
  font-size: 13px;
  text-align: left;
}

.batchesCreated .requestDeatailCardBody .nextBatch .title {
  color: #8e9298;
  font-size: 12px;
}

.batchesCreated .requestCards .requestDeatailCardBody .btn button {
  color: #494949;
}

.guruRequestSlider .sliderTabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease-in-out;
}
.guruRequestSlider .sliderTab {
  width: 50%;
  padding: 1rem;
  text-align: center;
  color: #252a31;
  font-size: 14px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}
.guruRequestSlider .selected {
  background-color: #f4f4f4;
  border-color: #f5a041;
}

.guruRequestSlider .reiewCards {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.guruRequestSlider .reviews {
  margin-top: 1rem;
}
.guruRequestSlider .reviews .reviews {
  margin-top: 0rem;
}
.guruRequestSlider .container {
  padding: 2rem 0 1rem !important;
}

.guruRequestSlider .row .amounts {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  color: #494949;
}
.guruRequestSlider .row .amounts span {
  background-color: #dbeefe;
  padding: 5px 13px;
  border-radius: 5px;
}
