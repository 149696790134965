.selectedTab {
  padding: 1rem 1.75rem;
}

.selectedTab .tabHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.selectedTab .tabHeader .tabTitle {
  color: #555a5c;
  font-size: 13px;
}

.selectedTab .tabHeader .tabTitle span {
  color: #19302d;
  font-weight: 600;
}

.students .studentDetailCard .search {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.students .studentDetailCard .search .searchInput {
  position: relative;
}
.students .studentDetailCard .search .searchInput svg {
  position: absolute;
  top: 8.2px;
  left: 12px;
  width: 15px;
}

.students .studentDetailCard .search .searchInput input {
  border: 1px solid #dadada;
  border-radius: 10px;
  padding: 10px 10px 10px 35px;
  background-color: transparent;
  width: 225px;
  outline: none;
  color: #494949;
}

.students .studentDetailCard .search .filter {
  position: relative;
}

.students .studentDetailCard .search .filter svg {
  position: absolute;
  top: 8.2px;
  left: 12px;
  width: 15px;
}

.students .studentDetailCard .search .filter button {
  border: none;
  border-radius: 10px;
  padding: 10px 15px 10px 35px;
  background-color: #494949;
  outline: none;
  color: #ffffff;
  cursor: pointer;
}

.students
  .studentDetail
  .studentDetailCard
  .selectedTab
  .tabBody
  .requestCards {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  margin-top: 1rem;
}
.studentDetail .selectedTab .tabBody .requestCard {
  background-color: #ffffff;
  border: 1px solid #f6f8f7;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px #b8b4b44d;
  width: 30%;
}
.studentDetail .selectedTab .tabBody .requestCardHeader {
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.studentDetail .selectedTab .tabBody .requestCardHeader .icon {
  width: 45px;
  height: 45px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #edf6fe;
}
.studentDetail .selectedTab .tabBody .requestCardHeader .icon svg {
  width: 25px;
}
.studentDetail .selectedTab .tabBody .requestCardHeader .cardTitle {
  font-size: 13px;
  color: #252a31;
}
.studentDetail .selectedTab .tabBody .requestCardHeader .cardTitle .location {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  color: #494949;
  gap: 5px;
  margin-top: 5px;
}
.studentDetail
  .selectedTab
  .tabBody
  .requestCardHeader
  .cardTitle
  .location
  svg {
  width: 15px;
}

.studentDetail .selectedTab .tabBody .requestCard .requestCardBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #f8f8f8;
  color: #494949;
  font-size: 13px;
}
.studentDetail .selectedTab .tabBody .requestCard .requestCardBody button {
  border: 2px solid #dddddd;
  border-radius: 8px;
  padding: 8px 10px;
  font-size: 13px;
  background-color: transparent;
  color: #494949;
  cursor: pointer;
}
.studentDetail .selectedTab .tabBody .requestCard .requestCardBody .responses {
  font-size: 12px;
  color: #494949;
}

.requestDetailSliderBG {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: end;
  background-color: #5d5d5d70;
  overflow: hidden;
}

.requestDetailSliderBG .requestDetailSlider {
  background-color: #f7f7f7;
  width: 30%;
  height: 100%;
  padding: 2rem 1rem;
}

.requestSliderOverlay.overlay-after-open {
  background-color: rgba(0, 0, 0, 0.07) !important;
}

.requestSlider {
  box-shadow: 0px 4px 17px 0px #b8b4b496 !important;
  background-color: #f7f7f7 !important;
}

.requestSlider .slide-pane__content {
  padding: 2rem 1rem;
}

.requestSlider .requestCardHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 0.5rem;
}
.requestSlider .requestCardHeader .icon {
  width: 45px;
  height: 45px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #edf6fe;
}
.requestSlider .requestCardHeader .icon svg {
  width: 25px;
}
.requestSlider .requestCardHeader .cardTitle {
  font-size: 13px;
  color: #252a31;
}
.requestSlider .requestCardHeader .cardTitle .location {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  color: #494949;
  gap: 5px;
  margin-top: 5px;
}
.requestSlider .requestCardHeader .cardTitle .location svg {
  width: 8px;
}
.requestSlider .responses {
  color: #494949;
  font-size: 14px;
  padding: 1rem 0;
}

.requestSlider .requestDeatailCards {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.requestSlider .requestDeatailCard {
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 4px 7px 0px #b8b4b44d;
  padding: 1rem 1rem;
}

.requestSlider .requestDeatailCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 0.5rem;
  font-size: 14px;
  color: #252a31;
}

.requestSlider .requestDeatailCardHeader .section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.requestSlider .requestDeatailCardHeader .icon {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #edf6fe;
}
.requestSlider .requestDeatailCardHeader .icon svg {
  width: 18px;
}
.requestSlider .requestDeatailCardHeader .title {
  font-size: 13px;
  color: #252a31;
}
.requestSlider .requestDeatailCardHeader .amount {
  font-size: 13px;
  color: #494949;
  font-weight: 600;
  background-color: #ececec;
  padding: 5px 8px;
  border-radius: 8px;
}

.requestSlider .requestDeatailCardBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0 0.5rem;
  font-size: 14px;
  color: #252a31;
}

.requestSlider .requestDeatailCardBody .section {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
}

.requestSlider .requestDeatailCardBody .section .userImg {
  width: 45px;
  height: 45px;
  border-radius: 100%;
}
.requestSlider .requestDeatailCardBody .section .userImg img {
  border-radius: 100%;
  max-width: 100%;
}

.requestSlider .requestDeatailCardBody .section .cardUserData .userName {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  color: #252a31;
  font-size: 14px;
}

.requestSlider .requestDeatailCardBody .section .cardUserData .userName svg {
  width: 18px;
}

.requestSlider .requestDeatailCardBody .section .cardUserData .ratings {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.requestSlider .requestDeatailCardBody .section .cardUserData .ratings svg {
  width: 12px;
}

.requestSlider .requestDeatailCardBody .timings {
  text-align: right;
  font-size: 12px;
  color: #252a31;
}
.requestSlider .requestDeatailCardBody .timings .days {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
}

.requestSlider .requestDeatailCardBody .timings .days span {
  background-color: #4cabfd;
  color: #ffffff;
  border-radius: 5px;
  padding: 3px 5px;
}
