 

.guruzAdmin {
  background-color: #f6f8f7;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 100vh;
}

.guruzAdmin .sidebarWrapper {
  width: 5.25%;
  position: fixed;
  height: 100vh;
  background-color: #494949;
}

.guruzAdmin .guruzAdminMain {
  width: 94.75%;
  margin-left: 5.25%;
}
.guruzAdmin .guruzAdminMain .headerWrapper {
  background-color: #ffffff;
  height: 11.5vh;
  position: fixed;
  width: calc(100% - 5.25%);
}
.guruzAdmin .guruzAdminMain .guruzAdminBody {
  margin-top: 11.5vh;
}

.loader_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader svg {
  fill: #f5a041;
  width: 60px;
}

.text-center {
  text-align: center;
}
.text-14 {
  font-size: 14px;
}
.bg-transparent {
  background-color: transparent !important;
}
