.gurus .verified {
  display: flex;
  align-items: center;
  gap: 5px;
}
.gurus .verified svg {
  width: 15px;
}

.gurus .analytics .joinedLeft {
  justify-content: space-between;
}
.gurus .analytics .joinedLeft .section:first-child {
  border-right: 1px solid #ececec;
  padding-right: 1rem;
}
.gurus .analytics .joinedLeft .section:last-child {
  border-left: 1px solid #ececec;
  padding-left: 1rem;
}
.container {
  position: relative;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 282px;
  left: 0;
  right: 0;
  bottom: 0;
}

.container >tbody{
  min-height: 400px;
}


.chartCenter{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width:100%
}