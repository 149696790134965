/* .page {
  padding: 1.6rem 1.8rem;
}

.page .pageNmae div:first-child {
  font-size: 13px;
  color: #494949;
  font-weight: 600;
  margin-bottom: 5px;
}
.page .pageNmae div:last-child {
  font-size: 12px;
  color: #898989;
  font-weight: 500;
} */
.students {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
.students .studentSection1 {
  width: 78.5%;
}
/* .students .studentSection2{
  width: 25%;
} */

.students .studentsHeader {
  /* width: 75%; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.students .studentsHeader .search {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.students .studentsHeader .search .searchInput {
  position: relative;
}
.students .studentsHeader .search .searchInput svg {
  position: absolute;
  top: 8.2px;
  left: 12px;
  width: 15px;
}

.students .studentsHeader .search .searchInput input {
  border: 1px solid #dadada;
  border-radius: 10px;
  padding: 10px 10px 10px 35px;
  background-color: transparent;
  width: 250px;
  outline: none;
  color: #494949;
}

.students .studentsHeader .search .filter {
  position: relative;
}

.students .studentsHeader .search .filter svg {
  position: absolute;
  top: 8.2px;
  left: 12px;
  width: 15px;
}

.students .studentsHeader .search .filter button {
  border: none;
  border-radius: 10px;
  padding: 10px 15px 10px 35px;
  background-color: #494949;
  outline: none;
  color: #ffffff;
  cursor: pointer;
}

.students .studentBody {
  display: flex;
  justify-content: space-between;
  /* align-items: flex-start; */
  gap: 1rem;
  margin-top: 1rem;
}
.students .studentBody .studentList {
  width: 100%;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 4px 7px 0px #b8b4b44d;
  padding-bottom: 2rem;
}

.students .studentBody .studentList .tableTitle {
  padding: 1.5rem 2rem 1.25rem;
  color: #494949;
  font-size: 13px;
  font-weight: 600;
}

.students .studentBody .studentList table {
  width: 100%;
  caption-side: bottom;
  border-collapse: collapse;
  font-size: 12px;
}
.students .studentBody .studentList table td {
  padding: 1rem 0;
}
.students .studentBody .studentList table thead {
  color: #555a5c;
  font-weight: 600;
}
.students .studentBody .studentList table thead td:first-child {
  padding-left: 2rem;
}
.students .studentBody .studentList table thead td:last-child {
  padding-right: 2rem;
}
.students .studentBody .studentList table tbody tr td:first-child {
  padding-left: 2rem;
}
.students .studentBody .studentList table tbody tr td:last-child {
  padding-right: 2rem;
}
.students .studentBody .studentList table tbody tr:nth-child(odd) {
  background-color: #f6f8f7;
}
.students .studentBody .studentList table tbody {
  max-height: 60vh;
  overflow: auto;
  display: block;
  width: 100%;
}
.students .studentBody .studentList table tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.students .studentBody .studentList table tbody tr {
  color: #999999;
  cursor: pointer;
}
.students .studentBody .studentList table tbody tr td .suspend {
  border: 1px solid #e61616;
  border-radius: 5px;
  padding: 5px 10px;
  color: #e61616;
  background-color: #fce7e7;
  font-size: 12px;
}
.students .studentBody .studentList table tbody tr td .revoke {
  border: 1px solid #14a81a;
  border-radius: 5px;
  padding: 5px 10px;
  color: #14a81a;
  background-color: #e7f6e8;
  font-size: 12px;
}
.students .studentBody .studentList table tbody tr td .loading {
  border: 1px solid #e6b800;
  border-radius: 5px;
  padding: 5px 10px;
  color: #e6b800;
  background-color: #fff9c4;
  font-size: 12px;
}
.students .studentBody .studentList table tbody tr td .action {
  cursor: pointer;
}
.students .studentBody .studentList table tbody tr .feeSpent {
  color: #14a800;
  font-weight: 600;
}
.students .studentBody .studentList table thead tr .actions {
  width: 50px;
}
.students .studentBody .studentList table tr .actions {
  width: 30px;
}
.students .studentBody .studentList table tr .subscription {
  width: 100px;
}

.students .analytics {
  width: 21.5%;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 4px 7px 0px #b8b4b44d;
  padding: 1rem 1rem 0.5rem;
  margin-top: 53px;
}

.students .analytics .analyticsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.students .analytics .title {
  color: #494949;
  font-size: 13px;
  font-weight: 600;
}

.students .analytics .analyticsView {
  background-color: #f6f8f7;
  color: #555a5c;
  font-size: 13px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  padding: 5px 10px;
}
.students .analytics .analyticsView span {
  padding: 3px;
  border-radius: 5px;
  cursor: pointer;
}
.students .analytics .analyticsView .selected {
  background-color: #ffffff;
  box-shadow: 0px 4px 7px 0px #b8b4b44d;
}

.students .analytics .totalStudents {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 0;
  gap: 1rem;
  color: #494949;
  font-size: 18px;
  font-weight: 600;
  border-bottom: 1px solid #ececec;
}

.students .analytics .totalStudents .titie {
  color: #494949;
  font-size: 14px;
  font-weight: 500;
}

.students .analytics .totalStudents .icon {
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background-color: #4cabfd1a;
}
.students .analytics .totalStudents .icon svg {
  width: 25px;
}
.students .analytics .totalStudents .icon svg g path {
  stroke: #4cabfd;
}
.students .analytics .joinedLeft {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5rem 0;
  border-bottom: 1px solid #ececec;
}
.students .analytics .joinedLeft .section {
  color: #555a5c;
  font-size: 12px;
}

.students .analytics .joinedLeft .section:first-child {
  border-right: 1px solid #ececec;
  padding-right: 2rem;
}
.students .analytics .joinedLeft .section:last-child {
  padding-left: 2rem;
}

.students .analytics .joinedLeft .section .stats {
  color: #19302d;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
}

.students .analytics .joinedLeft .section .stats svg {
  width: 15px;
}

.students .analytics .feeAnalytics {
  padding: 1.5rem 0 0.5rem;
}
.students .analytics .feeAnalytics .studentSpent {
  font-size: 12px;
  color: #555a5c;
  margin-top: 10px;
}
.students .analytics .feeAnalytics .studentSpent .amount {
  font-size: 14px;
  color: #14a81a;
  font-weight: 600;
  margin-top: 5px;
}
.students .analytics .feeAnalytics .feeAnalyticsChart {
  height: 180px;
  margin-left: -50px;
  margin-top: 1rem;
  font-size: 12px;
  color: #555a5c;
}
.container {
  position: relative;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 282px;
  left: 0;
  right: 0;
  bottom: 0;
}
.container >tbody{
  min-height: 400px;
}


.chartCenter{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width:100%
}

.suspend .revoke .loading{

  display: flex;
  
  gap:12px
}