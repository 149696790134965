.visibilityHidden {
  visibility: hidden;
}
a {
  text-decoration: none;
}

.resumeVector_login_left {
  position: fixed;
  width: 200px;
  left: 0;
  bottom: 10px;
}
.resumeVector_login_right {
  position: fixed;
  width: 200px;
  right: 0;
  bottom: 10px;
}

.app {
  background-color: #ec6336;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  flex-direction: column;
  height: 100vh;
}

.hero {
  display: flex;
  justify-content: start;
  align-items: start;
  width: 30%;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 10px;
  z-index: 1;
}
.logo {
  margin-bottom: 1rem;
}
.logo img {
  max-width: 100px;
}
.main {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  padding: 42px 42px 0;
  border-radius: 10px;
}

.main_header {
  display: flex;
  grid-gap: 0;
  justify-content: space-between;
  flex-direction: column;
  min-height: 55px;
}
.main_header span {
  display: flex;
  justify-content: end;
}
.main_header .main_header_left {
  display: flex;
  gap: 2.5rem;
}
.main_header .error {
  display: flex;
  gap: 2.5rem;
  justify-content: center;
  align-items: center;
  min-height: 15px;
}
.main_header .error p {
  margin: 0;
}
.main_header_login {
  color: #ec6336;
  margin-bottom: 0;
  width: 130%;
  text-align: center;
  cursor: pointer;
}
.main_header_login:hover {
  text-decoration: none;
  color: #ec6336;
}
.main_header_signup:hover {
  text-decoration: none;
  color: #ec6336;
}
.main_header_signup {
  color: #edb9a8;
  margin-bottom: 0;
  width: 130%;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
}
.main_header_active {
  width: 100%;
  height: 5px;
  background: #ec6336;
  border-radius: 20px;
  margin-top: 0;
}

.email_login {
  margin-top: 0rem !important;
  border-radius: 5px;
  border: none;
  outline: none;
  background: #dce2ed52;
  width: 90%;
  padding: 1rem;
  font-size: 12px;
}
.pwd_container {
  position: relative;
}
.password {
  margin: 1.5rem 0;
  border-radius: 5px;
  border: none;
  outline: none;
  background: #dce2ed52;
  width: 90%;
  padding: 1rem;
  font-size: 12px;
}
.pwd_eye {
  width: 16px;
  position: absolute;
  top: 42px;
  right: 15px;
  cursor: pointer;
}
.main_forgot {
  display: flex;
  font-size: 10px;
  justify-content: space-between;
  padding: 1rem;
}

.remember_me {
  display: flex;
  gap: 0.5rem;
  cursor: pointer;
}
.remember_me_label {
  cursor: pointer;
  align-items: center;
  display: flex;
  margin: 0;
}
.forgotPassword {
  margin: 0;
  cursor: pointer;
  text-decoration: none;
  color: currentColor;
}

.forgotPassword:hover {
  text-decoration: none;
  color: #ec6336;
}

.btn_login {
  display: flex;
  justify-content: center;
  align-items: center;
  gap:10px;
  width: 100%;
  border: none;
  background: #ec6336;
  color: white;
  border-radius: 10px;
  padding: 9px;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid transparent;
  outline: none;
}
.btn_login:hover {
  background: white;
  color: #ec6336;
  border: 1px solid #ec6336;
}

.btn_social {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  justify-content: center;
  align-items: center;
}
.btn_social span {
  opacity: 1 !important;
}

.credit {
  font-size: 10px;
  margin-top: 2rem;
}

.credit a {
  color: #ec6336;
  text-decoration: none;
  cursor: pointer;
}

.error {
  color: red;
}

.loader {
  width: 100px !important;
  margin-bottom: 10px;
  visibility: visible;
  height: 15px;
}

@media only screen and (max-width: 768px) {
  .resumeVector_login_left {
    width: 130px;
  }
  .resumeVector_login_right {
    width: 130px;
  }
  .hero {
    width: 60%;
  }
}

@media only screen and (max-width: 426px) {
  .resumeVector_login_left {
    width: 130px;
  }
  .resumeVector_login_right {
    width: 130px;
  }
  .hero {
    width: 80%;
  }
  .main {
    padding: 22px 22px 0;
  }

  .extraMarginBottom {
    margin-bottom: 15px;
  }
  .btn_google {
    gap: 0rem !important;
  }
}
