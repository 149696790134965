.feedbacks .tabBody .requestCard .requestCardBody {
  gap: 10px;
  align-items: inherit !important;
  padding: 1rem 1rem 1.5rem !important;
}
.feedbacks .tabBody .requestCard .requestCardBody .txnRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  border-bottom: 1px solid #d3d4d5;
}
.transactions .tabBody .requestCard .requestCardBody .txnRow .feedUserData {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  color: #252a31;
  font-size: 14px;
}
.transactions
  .tabBody
  .requestCard
  .requestCardBody
  .txnRow
  .feedUserData
  .userImg {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.transactions
  .tabBody
  .requestCard
  .requestCardBody
  .txnRow
  .feedUserData
  .userImg
  img {
  max-width: 100%;
  border-radius: 100%;
}

.transactions
  .tabBody
  .requestCard
  .requestCardBody
  .txnRow
  .feedUserData
  .ratings {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
}
.transactions
  .tabBody
  .requestCard
  .requestCardBody
  .txnRow
  .feedUserData
  .ratings
  svg {
  width: 15px;
}
