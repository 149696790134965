.manageCategories .filter button {
  padding: 0.65rem 1rem !important;
}

.manageCategories .categoryCard .requestCardBody {
  padding: 1rem !important;
}

.manageCategories .categoryCard .categoryCardHeader {
  align-items: center;
}

.manageCategories .categoryCard .categoryCardHeader .title {
  font-size: 14px;
  color: #494949;
}

.manageCategories .categoryCard .categoryCardHeader svg {
  width: 5px;
  height: 15px;
}

.manageCategories .categoryCard .categoties {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}
.manageCategories .categoryCard .categoties span {
  border: 1px solid #e0e0e0;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  font-size: 13px;
}

.addCategorySlider .requestCardHeader {
  padding-bottom: 1.5rem;
}

.addCategorySlider .addCategoryBody {
  padding: 2rem 0 0;
}

.addCategorySlider .addCategoryBody .title input {
  background-color: transparent;
  border: 1px solid #bababa;
  border-radius: 15px;
  padding: 0.5rem 1rem;
  width: 90%;
  outline: none;
}

.addCategorySlider .addSubCatrgory {
  padding: 1rem;
  background-color: #ebebeb;
  border-radius: 10px;
  margin: 1rem 0;
}

.addCategorySlider .addSubCatrgory .addSubCatrgoryHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  color: #4cabfd;
  font-size: 14px;
  border-bottom: 1px solid #d4d4d4;
  padding-bottom: 0.5rem;
}

.addCategorySlider .addSubCatrgory .addSubCatrgoryHeader svg {
  width: 20px;
}

.addCategorySlider .addSubCatrgory .addSubCatrgorybody {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
}

.addCategorySlider .addSubCatrgory .addSubCatrgorybody .subCategory {
  border: 1px solid #bababa;
  border-radius: 15px;
  padding: 0.25rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #494949;
}

.addCategorySlider .addSubCatrgory .addSubCatrgorybody .subCategory svg {
  width: 20px;
  height: 20px;
}
.rounded-input {
  display: flex;
  align-items: center;
  border-radius: 20px;
  /* background-color: #f2f2f2; */
  padding: 0 10px;
}

.rounded-input > input {
  border: none;
  outline: none;
  width: 450px;
  background-color: transparent;
  flex: 1;
  padding: 8px 0;
}

.input-icon {
  margin-left: 8px;
  color: #999999;
  font-size: 20px;
}