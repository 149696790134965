/* .students {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
.students .studentSection1 {
  width: 78.5%;
} */
.studentDetails {
  flex-direction: column;
}
.students .studentDetail {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  position: relative;
  /* margin-top: 0.5rem; */
}

.students .studentDetail .studentProfileCard {
  width: 30%;
  border-radius: 15px;
  box-shadow: 0px 4px 7px 0px #b8b4b44d;
  background-color: #ffffff;
  padding-bottom: 1rem;
}
.students .studentDetail .studentDetailCard {
  width: 70%;
  border-radius: 15px;
  box-shadow: 0px 4px 7px 0px #b8b4b44d;
  background-color: #ffffff;
  padding-bottom: 1rem;
}

.students .studentDetail .studentProfileCard .profileCardHeader {
  padding: 1.25rem 1rem;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background: linear-gradient(to right, #f5a041 0%, #4cabfd 130%);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.students .studentDetail .studentProfileCard .profileCardHeader svg {
  width: 25px;
  cursor: pointer;
}

.students .studentDetail .studentProfileCard .profileUserDetail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  position: relative;
}

.students .studentDetail .studentProfileCard .profileUserDetail .img {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 100%;
  position: absolute;
  top: -40px;
}

.students .studentDetail .studentProfileCard .profileUserDetail .img img {
  max-width: 98%;
  border-radius: 100%;
}

.students .studentDetail .studentProfileCard .profileUserDetail .userName {
  margin-top: 45px;
  font-weight: 600;
  color: #555a5c;
  font-size: 16px;
}

.students .studentDetail .studentProfileCard .profileUserDetail .location {
  font-weight: 500;
  color: #b2b2b2;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.students .studentDetail .studentProfileCard .userAppData {
  padding: 1rem;
}

.students .studentDetail .studentProfileCard .userAppData .row {
  padding: 1rem 0;
  color: #19302d;
  font-weight: 600;
  font-size: 14px;
  border-bottom: 1px solid #d4d4d4;
}

.students .studentDetail .studentProfileCard .userAppData .row .rowTitle {
  color: #555a5c;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 5px;
}

.students .studentDetail .studentDetailCard .studentDetailCardTabs {
  display: flex;
  align-items: center;
}
.students .studentDetail .studentDetailCard .studentDetailCardTab:first-child {
  border-top-left-radius: 15px;
}
.students .studentDetail .studentDetailCard .studentDetailCardTab:last-child {
  border-top-right-radius: 15px;
}
.students .studentDetail .studentDetailCard .studentDetailCardTab {
  padding: 2rem;
  color: #494949;
  font-size: 13px;
  font-weight: 600;
  width: 25%;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  border-bottom: 2px solid transparent;
  text-align: center;
}
.students .studentDetail .studentDetailCard .selected {
  background-color: #fbfbfb;
  border-color: #f5a041;
}
