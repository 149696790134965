.splashScreenImages .tabBody {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  padding: 1rem 0;
}

.splashScreenImages .tabBody .uploadImg {
  width: 200px;
  height: 200px;
  border-radius: 10px;
  background-color: #f6f8f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #f5a041;
  font-size: 13px;
}

.splashScreenImages .tabBody .uploadImg svg {
  width: 30px;
}

.splashScreenImages .tabBody .uploadedImages {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
  width: 80%;
}
.splashScreenImages .tabBody .uploadedImages .Image {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 200px;
  position: relative;
}

.splashScreenImages .tabBody .uploadedImages .Image .remove {
  position: absolute;
  top: 10px;
  right: 10px;
}
.splashScreenImages .tabBody .uploadedImages .Image .remove svg {
  width: 20px;
}

.splashScreenImages .tabBody .uploadedImages .Image img {
  width: 200px;
  height: 200px;
  border-radius: 10px;
}

.splashScreenImages .tabBody .uploadedImages .Image .imgTagline input {
  background-color: transparent;
  border-radius: 15px;
  padding: 0.5rem 1rem;
  width: 80%;
  border: 1px solid #d4d4d4;
  font-size: 13px;
  outline: none;
}
