.transactions .tabBody .requestCard {
  width: 100% !important;
}
.transactions .tabBody .requestCard .requestCardBody {
  flex-direction: column;
  gap: 8px;
  align-items: inherit !important;
  padding: 0.5rem 1rem;
  font-size: 12px !important;
}
.transactions .tabBody .requestCard .requestCardBody .txnRow {
  display: flex;
  justify-content: space-between;
  align-content: center;
  color: #707070;
}
.transactions .tabBody .requestCard .requestCardBody .txnRow .title {
  color: #19302d;
}
