.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  height: 100%;
}

.sidebar .logo {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 8px; */
  background-color: #f5a041;
  height: 11.5vh;
}
.sidebar .logo svg {
  width: 18px;
}

.sidebar .pageLinks {
  padding: 2rem 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.sidebar .pageLinks svg {
  width: 14px;
}

.sidebar .pageLink {
  padding: 8px 12px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.sidebar .active {
  background-color: #ffffff21;
}

.sidebar .active svg {
  width: 12px;
}

.sidebar .settings {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 11.5vh;
  background-color: #00000038;
  cursor: pointer;
}

.sidebar .settings svg {
  width: 18px;
}
.sidebar .settings .pageLink {
  padding: 4px 8px;
}
.sidebar .settings .active svg {
  width: 16px !important;
}
