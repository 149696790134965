.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem 0 1.25rem;
  height: 100%;
}

.header .search {
  position: relative;
}

.header .search input {
  border: none;
  background-color: transparent;
  outline: none;
  color: #494949;
  padding: 10px 20px 10px 30px;
  border-radius: 5px;
}

.header .search input:focus {
  border: 1px solid #494949ad;
}

.header .search svg {
  position: absolute;
  width: 15px;
  top: 9px;
  left: 9px;
}

.header .userDetail {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: right;
  cursor: pointer;
}

.header .userDetail .userName {
  color: #494949;
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 5px;
}
.header .userDetail .role {
  color: #8b8b8b;
  font-size: 12px;
  text-transform: capitalize;
}
.header .userDetail .userImg {
  width: 37px;
  height: 37px;
}
.header .userDetail .userImg img {
  max-width: 100%;
  border-radius: 100%;
}
.header .userDetail .arrowDown svg {
  width: 14px;
}
