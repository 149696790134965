.termsAndCond .tabBody {
  padding: 1rem 0;
  display: flex;
  align-items: inherit;
  flex-direction: column;
  gap: 1rem;
}

.termsAndCond .termsInput {
  width: 100%;
}

.termsAndCond .termsInput textarea {
  width: 97%;
  padding: 1rem;
  border-radius: 15px;
  border: 1px solid #c8c8c8;
  color: #494949;
  font-size: 13px;
  outline: none;
  min-height: 100px;
  resize: vertical;
}

.termsAndCond .saveBtn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.termsAndCond .saveBtn button {
  border: none;
  background-color: #494949;
  padding: 0.5rem 1.5rem;
  color: white;
  font-size: 12px;
  /* font-weight: 600; */
  border-radius: 8px;
}
