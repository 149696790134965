.page {
  padding: 1.6rem 1.8rem;
}

.page .pageNmae div:first-child {
  font-size: 13px;
  color: #494949;
  font-weight: 600;
  margin-bottom: 5px;
}
.page .pageNmae div:last-child {
  font-size: 12px;
  color: #898989;
  font-weight: 500;
}

.page .dashboardWidgets {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  gap: 1rem;
}

.page .dashboardWidgets .dashboardWidget {
  border-radius: 15px;
  padding: 1.75rem 1.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #ffffff;
  gap: 15px;
  width: 32%;
  box-shadow: 0px 4px 7px 0px #b8b4b44d;
}
.page .dashboardWidgets .dashboardWidget .widgetIcon {
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
}

.page .dashboardWidgets .dashboardWidget .widgetIcon svg {
  width: 25px;
}

.page .dashboardWidgets .dashboardWidget .widgetStats {
  font-size: 18px;
  font-weight: 600;
  color: #494949;
}
.page .dashboardWidgets .dashboardWidget .widgetStats .widgetName {
  font-size: 13px;
  font-weight: 500;
  color: #898989;
  margin-top: 5px;
}

.page .dashboardWidgets .totalStudent .widgetIcon {
  background-color: #f5a0411c;
}
.page .dashboardWidgets .totalGuruz .widgetIcon {
  background-color: #f5a0411c;
}
.page .dashboardWidgets .totalRevenue .widgetIcon {
  background-color: #4cabfd1a;
}

.page .dashboardAnalytics {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  margin-top: 1rem;
  gap: 1rem;
}

.page .dashboardAnalytics .dashboardAnalytic {
  border-radius: 15px;
  padding: 2rem 0 0;
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
  flex-direction: column;
  background-color: #ffffff;
  gap: 15px;
  width: 32%;
  box-shadow: 0px 4px 7px 0px #b8b4b44d;
  min-height: 290px;
}

.page .dashboardAnalytics .dashboardAnalytic .analyticHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  padding: 0 1.5rem 1.5rem;
}
.page .dashboardAnalytics .dashboardAnalytic .analyticHeader .analyticName {
  font-weight: 600;
  color: #494949;
  font-size: 13px;
}
.page .dashboardAnalytics .dashboardAnalytic .analyticHeader div:last-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  color: #898989;
  font: 12px;
}

.page
  .dashboardAnalytics
  .dashboardAnalytic
  .analyticHeader
  div:last-child
  div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.page
  .dashboardAnalytics
  .dashboardAnalytic
  .analyticHeader
  div:last-child
  .dot {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.page .dashboardAnalytics .dashboardAnalytic .analyticHeader .joined .dot {
  background-color: #f5a041;
}
.page .dashboardAnalytics .dashboardAnalytic .analyticHeader .left .dot {
  background-color: #4cabfd;
}
.page .dashboardAnalytics .dashboardAnalytic .analiticChart {
  height: 230px;
  margin-left: -40px;
  font-size: 12px;
}

.page .dashboardAnalytics .guruzAnalytics {
  gap: 0;
  padding-top: 1.8rem;
}
.page .dashboardAnalytics .guruzAnalytics .analyticHeader {
  padding-bottom: 1rem;
}

.page .dashboardAnalytics .dashboardAnalytic .analyticHeader .analyticTabs {
  background-color: #f6f8f7;
  font-size: 14px;
  border-radius: 5px;
  font-size: 13px;
  /* padding: 0px; */
}

.page .dashboardAnalytics .dashboardAnalytic .analyticHeader .analyticTab {
  padding: 8px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: #1f1f1f !important;
}
.page .dashboardAnalytics .dashboardAnalytic .analyticHeader .selected {
  background-color: #fff;
  box-shadow: 0px 0px 10px 1px #b8b4b44d;
}

.page .dashboardAnalytics .dashboardAnalytic .analyticTiles {
  padding: 0 10px;
}
.page .dashboardAnalytics .dashboardAnalytic .analyticTile {
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
}
.page .dashboardAnalytics .dashboardAnalytic .analyticTile .analyticTileDetail {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.page
  .dashboardAnalytics
  .dashboardAnalytic
  .analyticTile
  .analyticTileDetail
  .img {
  width: 35px;
  height: 35px;
}
.page
  .dashboardAnalytics
  .dashboardAnalytic
  .analyticTile
  .analyticTileDetail
  .img
  img {
  max-width: 100%;
  border-radius: 100%;
}
.page
  .dashboardAnalytics
  .dashboardAnalytic
  .analyticTile
  .analyticTileDetail
  .name {
  font-size: 16px;
  color: #252a31;
  margin-bottom: 5px;
}
.page
  .dashboardAnalytics
  .dashboardAnalytic
  .analyticTile
  .analyticTileDetail
  .ratings {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}
.page
  .dashboardAnalytics
  .dashboardAnalytic
  .analyticTile
  .analyticTileDetail
  .ratings
  svg {
  width: 14px;
}
.page .dashboardAnalytics .dashboardAnalytic .analyticTile .earning {
  font-size: 12px;
  color: #898989;
  text-align: right;
}
.page .dashboardAnalytics .dashboardAnalytic .analyticTile .earning .earned {
  font-size: 18px;
  color: #14a800;
  font-weight: 600;
}

.page .dashboardAnalytics .topCategories .analyticName {
  font-weight: 600;
  color: #494949;
  font-size: 15px;
  padding: 0 1.5rem;
}

.page .dashboardAnalytics .topCategories .categoryChart {
  display: flex;
  justify-content: center;
  align-items: center;
}
.page .dashboardAnalytics .topCategories .categoryChartLabels {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 1.5rem 1rem;
  flex-wrap: wrap;
  gap: 10px;
}
.page .dashboardAnalytics .topCategories .categoryChartLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  color: #989898;
  gap: 10px;
  background-color: #f6f8f7;
  border-radius: 10px;
  font-size: 12px;
}
.page .dashboardAnalytics .topCategories .categoryChartLabel span {
  color: #494949;
  margin-left: 10px;
}
.page .dashboardAnalytics .topCategories .categoryChartLabel .dot {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: black;
}
